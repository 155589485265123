<template>
  <div class="readme-article">
    <h1 id="设置">七、设置</h1>
    <h2 id="线索来源渠道">1.线索来源渠道</h2>
    <p>1.1查看线索来源渠道列表，显示渠道信息。</p>
    <p><img src="@/assets/img/call/7-1.png" /></p>
    <p>
      1.2添加来源渠道，填写来源渠道名称、选择是否绑定上级渠道，下级渠道是否必选完成添加。
    </p>
    <p><img src="@/assets/img/call/7-2.png" /></p>
    <h2 id="线索意向状态">2.线索意向状态</h2>
    <p>2.1查看线索意向状态列表，显示意向状态信息，可编辑修改。</p>
    <p><img src="@/assets/img/call/7-3.png" /></p>
    <p>2.2添加线索意向状态，填写意向状态名称完成添加。</p>
    <p><img src="@/assets/img/call/7-4.png" /></p>
    <h2 id="流失原因">3.流失原因</h2>
    <p>查看流失原因设置列表，可添加删除流失原因。</p>
    <p><img src="@/assets/img/call/7-5.png" /></p>
    <h2 id="线索登记方式">4.线索登记方式</h2>
    <p>查看登记方式设置列表，可添加删除登记方式。</p>
    <p><img src="@/assets/img/call/7-6.png" /></p>
  </div>
</template>

<script>
export default {
  name: "call7-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>